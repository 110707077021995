import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import Hero from '../components/Hero';
import About from '../components/About';
import Advantages from '../components/Advantages';
import Cta from '../components/Cta';
import Form from '../components/Form';
import { DEFAULT_LANG } from '../constants';

import '../fragments.ts';
import { SEOQueryData } from '../fragments';

import '../styles/main.scss';

interface QueryData {
  sanityWebsite: {
    seo: SEOQueryData;
  };
  sanityHomepage: {
    seo?: SEOQueryData;
  };
}

const StripeSuccessPage = (): React.ReactElement => {
  return <div></div>;
};

export default StripeSuccessPage;
